
import { defineComponent } from 'vue'
import TopBar from '@/components/TopBar.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Footer from '@/components/Footer.vue'
import { setToken } from '@/utils/unit'
import BackTop from '@/components/BackTop.vue'
import CookieBox from '@/components/CookieBox.vue'

export default defineComponent({
  name: 'Cookie',
  components: {
    TopBar,
    Breadcrumb,
    Footer,
    BackTop,
    CookieBox,
  },
  setup() {
    const setCookie = () => {
      setToken('cookieValue', 7)
    }
    return{
      setCookie,
    }
  },
})
